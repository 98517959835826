.quote-template {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $c-white;
  border-radius: 8px;
  padding: 24px 24px;

  &__header {
    display: flex;
    column-gap: 8px;

    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .ant-alert {
    padding: 12px 24px;
    column-gap: 16px;
    font-size: 12px;
    line-height: 16px;
    border: none;
    background-color: $c-yellow-light;
  }

  &__mail {
    border: 1px solid $c-border;
    padding: 16px;

    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 8px;

      &--space {
        padding: 0 8px 4px;
      }

      & .input__wrapper,
      & .select__wrapper {
        flex-direction: row;
        align-items: center;

        label {
          width: 150px;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }

      .ant-select-selection-item {
        font-size: 12px;
        line-height: 14px;
      }

      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
          background-color: transparent !important;
          border: none !important;
        }
      }
    }

    &-label {
      border-top: 1px solid $c-border;
      padding: 8px 0 16px 0;
      font-size: 12px;
      line-height: 14px;
      color: $c-gray;
    }

    &-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 16px 32px;
      background-color: $c-background;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--link {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: $c-primary;

        span {
          font-size: 24px;
          line-height: 28px;
        }

        a {
          color: $c-primary;
          text-decoration: underline;
        }

        &::first-letter {
          text-decoration: none;
        }
      }

      & textarea {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        background-color: transparent;
        padding: 0;
      }

      &--divider {
        padding: 8px 0;
        color: $c-gray;
        font-size: 12px;
      }

      &--sender {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        span {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }

      &--icon {
        width: 150px;
        object-fit: contain;
      }

      &--telecoms {
        display: flex;
        gap: 12px;
        font-size: 12px;
        line-height: 14px;

        .left {
          font-weight: 600;
          color: $c-gray;

          & p::after {
            content: ":";
            padding-left: 12px;
            float: right;
          }
        }

        .right {
          font-weight: 400;
          color: $c-primary;
          text-decoration: underline;
        }
      }
    }
  }

  &__attachments {
    padding: 12px;
    background-color: $c-background;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--file {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &--unlink {
      cursor: pointer;
      display: flex;
    }

    &--fileIcon {
      height: 25px;
      object-fit: none;
    }

    &--fileName {
      color: $c-text;
      text-transform: lowercase;
    }

    &--fileType {
      color: $c-gray;
      font-size: 12px;
      text-transform: lowercase;
    }
  }

  &__cards {
    display: flex;
    gap: 8px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: $c-white;
    border: 2px solid $c-border;
    min-width: 116px;

    &--icon {
      height: 26px;
      width: 76px;
      object-fit: contain;
    }

    &--text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $c-gray;
      }
    }

    &--cost {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: $c-primary;
    }
  }

  &__main-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 12px;
  }

  &__container {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 24px;

    > div {
      padding: 12px 32px;
    }

    &--full {
      padding: 12px;
      background-color: $c-background;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
    }
  }

  &__header {
    margin: 0 auto;
    padding: 24px 0;

    img {
      height: 60px;
      object-fit: contain;
    }
  }

  &__submit--link,
  &__submit--link * {
    text-decoration: none;
  }

  &__submit {
    text-align: center;
    display: block;
    margin: 32px auto;
    padding: 24px 42px;
    background-color: #bd0027;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 700;
    border-radius: 100px;
    align-self: center;
    text-decoration: none;
  }

  &__content {
    padding: 20px 0;

    p {
      margin: 0 0 10px;
      line-height: 1.6;
    }
  }

  &__highlight {
    color: $c-radiance-template;
    font-weight: 700;
  }

  &__footer {
    text-align: center;
    font-size: 12px;
    color: #888888;
    padding-top: 10px;
    margin-top: 20px;

    &--title {
      font-size: 14px;
      color: #333;
    }

    a {
      color: #888888;
      text-decoration: none;
    }
  }

  &__info {
    p {
      margin: 0;
      font-size: 12px;
    }

    &--text {
      color: #888888;
    }

    &--name {
      font-size: 16px !important;
      font-weight: 600;
    }
    &--label {
      display: flex;
      margin: 0;
      font-size: 12px;
      column-gap: 8px;
      color: $c-text-sec;

      div {
        min-width: 50px;
      }

      span {
        color: $c-primary;
      }
    }
  }

  &__product-card {
    margin: 8px 0;
    border: 1px solid $c-gray-light;
    border-radius: 8px;

    &--container {
      margin: 42px 0;
    }

    &--body {
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 16px;
      flex-wrap: wrap;

      img {
        width: 110px;
        object-fit: contain;
      }
    }

    &--infos {
      display: flex;
      flex-direction: column;
      gap: 4px;

      small {
        font-size: 12px;
        font-weight: 400;
        color: $c-gray-dark;
      }

      span {
        font-size: 12px;
      }
    }

    &--price {
      color: $c-primary;
      font-weight: 600;
      font-size: 14px !important;
    }

    &--name {
      font-size: clamp(12px, 3vw, 16px);
      font-weight: 600;
    }

    &--link {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      cursor: pointer;
      color: $c-primary;
      text-decoration: underline;
    }
  }
}

.locked {
  & .quote-template__mail {
    pointer-events: none;
  }
}

.quote-template-solyon {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $c-white;
  border-radius: 8px;
  padding: 24px 24px;

  &__header {
    display: flex;
    justify-content: center;

    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }

    img {
      height: 48px !important;
      object-fit: contain;
    }
  }

  .ant-alert {
    padding: 12px 24px;
    column-gap: 16px;
    font-size: 12px;
    line-height: 16px;
    border: none;
    background-color: $c-yellow-light;
  }

  &__mail {
    border: 1px solid $c-border;
    padding: 16px;

    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 8px;

      &--space {
        padding: 0 8px 4px;
      }

      & .input__wrapper,
      & .select__wrapper {
        flex-direction: row;
        align-items: center;

        label {
          width: 150px;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }

      .ant-select-selection-item {
        font-size: 12px;
        line-height: 14px;
      }

      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
          background-color: transparent !important;
          border: none !important;
        }
      }
    }

    &-label {
      border-top: 1px solid $c-border;
      padding: 8px 0 16px 0;
      font-size: 12px;
      line-height: 14px;
      color: $c-gray;
    }

    &-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 16px 32px;
      background-color: $c-background;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--link {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: $c-primary-solyon;

        span {
          font-size: 24px;
          line-height: 28px;
        }

        a {
          color: $c-primary-solyon;
          text-decoration: underline;
        }

        &::first-letter {
          text-decoration: none;
        }
      }

      & textarea {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        background-color: transparent;
        padding: 0;
      }

      &--divider {
        padding: 8px 0;
        color: $c-gray;
        font-size: 12px;
      }

      &--sender {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        span {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }

      &--icon {
        width: 150px;
        object-fit: contain;
      }

      &--telecoms {
        display: flex;
        gap: 12px;
        font-size: 12px;
        line-height: 14px;

        .left {
          font-weight: 600;
          color: $c-gray;

          & p::after {
            content: ":";
            padding-left: 12px;
            float: right;
          }
        }

        .right {
          font-weight: 400;
          color: $c-primary;
          text-decoration: underline;
        }
      }
    }
  }

  &__attachments {
    padding: 12px;
    background-color: $c-background;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--file {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &--unlink {
      cursor: pointer;
      display: flex;
    }

    &--fileIcon {
      height: 25px;
      object-fit: none;
    }

    &--fileName {
      color: $c-text;
      text-transform: lowercase;
    }

    &--fileType {
      color: $c-gray;
      font-size: 12px;
      text-transform: lowercase;
    }
  }

  &__cards {
    display: flex;
    gap: 8px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: $c-white;
    border: 2px solid $c-border;
    min-width: 116px;

    &--icon {
      height: 26px;
      width: 76px;
      object-fit: contain;
    }

    &--text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $c-gray;
      }
    }

    &--cost {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: $c-primary;
    }
  }

  &__main-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 12px;
  }

  &__container {
    background-color: #ffffff;

    > div {
      padding: 16px 16px;
    }

    &--full {
      padding: 12px;
      background-color: $c-background;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
    }
  }

  &__header {
    margin: 0 auto;
    padding: 24px 0;
  }

  &__submit--link,
  &__submit--link * {
    text-decoration: none;
  }

  &__submit {
    text-align: center;
    display: block;
    padding: 16px 42px;
    background-color: rgba($c-primary-solyon, 0.2);
    color: rgba($c-primary-solyon, 1);
    border: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100px;
    align-self: center;
    text-decoration: none;
  }

  &__content {
    padding: 20px 0 8px;

    p {
      margin: 0 0 10px;
      line-height: 1.6;
      font-weight: 300;
      color: $c-secondary-solyon;
    }

    .subtitle {
      color: rgba($c-primary-solyon, 1);
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__highlight {
    color: $c-primary-solyon;
    font-weight: 700;
  }

  &__footer {
    text-align: center;
    font-size: 12px;
    color: #888888;
    padding-top: 10px;
    margin-top: 20px;

    &--title {
      font-size: 14px;
      color: #333;
    }

    a {
      color: #888888;
      text-decoration: none;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: $c-secondary-solyon;

    p {
      margin: 0;
      font-size: 14px;
    }

    &--text {
      color: #888888;
    }

    &--name {
      font-size: 16px !important;
      font-weight: 600;
    }

    &--label {
      display: flex;
      margin: 0;
      font-size: 12px;
      column-gap: 8px;
      color: $c-text-sec;

      div {
        min-width: 50px;
      }

      span {
        color: $c-primary;
      }
    }
  }

  &__product-card {
    margin: 8px 0;
    border: 1px solid $c-border-solyon;
    border-radius: 24px;

    &--container {
      margin: 32px 0;
    }

    &--body {
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;

      @media (width<=676px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 24px 16px 16px;
      }

      img {
        width: 90px;
        object-fit: contain;
      }
    }

    &--infos {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &--name {
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }

    &--sec-name {
      font-size: 12px !important;
      font-weight: 700;
      color: $c-gray-1-solyon;
      font-family: "Nunito";
    }

    &--small {
      font-size: 12px;
      color: $c-gray-dark;
    }

    &--price {
      display: flex;
      align-items: center;
      gap: 4px;

      .price {
        font-size: 32px;
        font-weight: 900;
        font-family: "Inter", sans-serif;
        display: inline;
        color: $c-secondary-solyon;
      }

      .currency {
        display: flex;
        flex-direction: column;
        gap: 2px;
        span {
          font-weight: 600;
        }

        small {
          color: rgba($c-primary-solyon, 1);
        }
      }
    }

    &--link {
      text-align: center;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px;
      background-color: rgba($c-primary-solyon, 1);
      color: $c-white;
      border: none;
      font-size: 16px;
      font-weight: 600;
      border-radius: 100px;
      align-self: center;
      text-decoration: none;
      max-width: 100%;
      box-shadow: 0px 8px 34px 0px rgba(128, 183, 38, 0.4);

      @media (width<=676px) {
        width: 100%;
      }
    }
  }
}
