.solyon {
  background-color: $c-white;
  // min-height: calc(100vh - 83px);
  // padding: clamp(24px, 5vw, 80px);

  // &__container {
  //   margin: auto;
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;
  //   row-gap: 80px;
  //   max-width: 1028px;

  //   &:has(.solyon-recap) {
  //     max-width: 1224px;
  //   }
  // }

  &__container {
    max-width: 1080px;
    margin: 0 auto;

    @media (max-width: 1399px) {
      max-width: 100%;
      padding: 0 64px;
    }

    @media (max-width: 1299px) {
      max-width: 1140px;
      padding: 0 32px;
    }

    @media (max-width: 1199px) {
      max-width: 100%;
      padding: 0 32px;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      padding: 0 32px;
    }

    @media (max-width: 576px) {
      max-width: 100%;
      padding: 0 16px;
    }

    @media (max-width: 400px) {
      max-width: 100%;
      padding: 0 16px;
    }
  }

  &__wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 80px;
    // max-width: 1028px;
    min-height: calc(100vh - 83px);
    padding: 80px 0;

    // &:has(.solyon-recap) {
    //   max-width: 1224px;
    // }
    @media (max-width: 991px) {
      padding: 40px 0;
      row-gap: 80px;
    }

    @media (width <=768px) {
      row-gap: 40px;
    }
  }

  &__body {
    * {
      font-family: "Nunito";
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 60px;
    position: relative;

    &--inputs {
      align-items: center;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &--actions {
      display: flex;
      justify-content: center;
      gap: 16px;
    }
  }

  .ant-modal-content {
    border-radius: 24px;
  }

  .input--errors {
    color: $c-danger-solyon;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
  }

  .max-500 {
    width: 500px;
    max-width: 100%;
  }

  @media (width <=768px) {
    min-height: calc(100vh - 60px);
    padding-bottom: 120px;

    &__container {
      row-gap: 40px;
    }

    &__body {
      row-gap: 40px;

      &--actions {
        width: 100%;
        background-color: $c-white;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 16px 24px 32px 24px;
        border-top: 1px solid $c-border-solyon;
        z-index: 20;

        .solyon-button {
          width: 100%;
        }
      }
    }
  }
}

.with-p-btm {
  @media (width <=768px) {
    padding-bottom: 60% !important;
  }
}

.solyon-radio-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;

  * {
    font-family: "Nunito";
  }

  .ant-radio-button-wrapper {
    background: transparent;
    flex: 1;
    height: auto !important;
    padding: 8px;
    text-align: center;
    border-radius: 24px !important;
    // max-width: 316px;
    min-width: 150px;
    padding: 40px 24px;
    border: 1px solid $c-border-solyon;

    &:hover {
      color: $c-primary-solyon;
    }
  }

  .ant-radio-button-wrapper:has(:checked) {
    opacity: 1;
    border: 1px solid $c-primary-solyon;

    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  .ant-radio-button-wrapper-checked {
    color: $c-primary-solyon;

    .solyon-radio-image {
      &__button {
        border: 6px solid $c-primary-solyon;
      }

      &__label {
        color: $c-secondary-solyon;
      }
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background: $c-default-border;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
    width: 0px;
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    row-gap: 24px;

    img {
      height: 120px;
      object-fit: contain;
      filter: grayscale(1);
      opacity: 0.5;
    }

    &--top {
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex: 1;
    }
  }

  &__label {
    font-size: clamp(16px, 2.5vw, 24px);
    font-weight: 700;
    color: $c-text-sec-solyon;
    padding-bottom: 12px;
    min-height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__subLabel {
    font-size: clamp(14px, 1.5vw, 16px);
    font-weight: 400;
    color: $c-text-sec-solyon;
    line-height: 1.2;
  }

  &__button {
    width: 20px;
    height: 20px;
    height: 20px;
    border: 1px solid $c-text-sec-solyon;
    border-radius: 50%;
  }

  @media (width <=768px) {
    &__body {
      flex-direction: row;
      justify-content: flex-start;
      column-gap: 24px;
    }

    &__label {
      text-align: left;
      padding: 0;
      padding-bottom: 4px;
      display: flex;
      flex-direction: column;
      min-height: auto;
    }

    &__subLabel {
      text-align: left;
    }

    img {
      height: auto;
      width: 52px;
    }

    &__button {
      display: none;
    }

    .ant-radio-button-wrapper {
      padding: 24px;
      max-width: none;

      &:hover {
        color: $c-primary-solyon;
      }
    }
  }
}

.solyon-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;

  * {
    font-family: "Nunito";
  }

  &__label {
    color: $c-text-sec-solyon;
    text-align: justify;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    column-gap: 24px;
  }
  .row {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .ant-radio-wrapper .ant-radio-checked::after {
    border: none;
  }
  .ant-radio-wrapper {
    span.ant-radio + * {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    margin-inline-end: 0px;

    &:hover {
      color: $c-primary-solyon;

      .ant-radio-inner {
        border-color: $c-primary-solyon;
      }
    }

    .ant-radio-inner {
      height: 24px;
      width: 24px;
      &::after {
        background-color: transparent;
      }
    }
  }

  .ant-radio-wrapper-checked {
    color: $c-primary-solyon;

    .ant-radio-inner {
      position: relative;
      border: none;
      background-color: $c-primary-solyon;

      &::after {
        color: $c-primary-solyon;

        opacity: 1;

        background-image: url("../../assets/icons/check_white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    .solyon-radio__label {
      color: $c-secondary-solyon;
    }
  }
}

.solyon-header {
  text-align: center;
  // margin: auto;
  // padding: 0 50px;
  width: 100%;

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    &--icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $c-text-sec-solyon;
      font-size: clamp(16px, 5vw, 48px);
      cursor: pointer;

      @media (width <=768px) {
        width: 24px;
        height: 24px;
      }
    }

    &--right {
      width: 48px;
      height: 48px;

      @media (width <=768px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__title {
    width: 80%;
    font-family: "Aptos";
    color: $c-secondary-solyon;
    font-size: clamp(22px, 5vw, 40px);
    font-weight: 600;
    line-height: 1.1;

    @media (width <=768px) {
      width: fit-content;
    }

    span {
      font-family: "Aptos";
      color: $c-primary-solyon;
    }
  }

  &__subtitle {
    font-family: "Nunito";
    color: $c-text-sec-solyon;
    font-size: clamp(12px, 2.5vw, 16px);
    padding-bottom: 8px;

    svg {
      color: $c-primary-solyon;
    }
  }

  @media (width <=768px) {
    // padding: 0 18px;
    margin: 0;

    &__row {
      // &--icon {
      //   left: 12px;
      // }
    }
  }
}

.solyon-header__small {
  padding: 0;

  .solyon-header__title {
    font-size: clamp(16px, 5vw, 24px);
  }

  .solyon-header__subtitle {
    font-size: 12px;
  }
}

.solyon-button {
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: start;
  }

  button {
    * {
      font-family: "Nunito";
    }

    width: 100%;
    padding: 8px 40px;
    height: 44px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 4px;
    border-radius: 100px;
    box-shadow: none;

    > div {
      width: 100%;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:disabled {
      background-color: $c-gray-2-solyon !important;
      color: $c-white;
      box-shadow: none;
    }

    &:hover:not(:disabled) {
      border-color: none !important;
      color: inherit !important;
    }
  }

  .small {
    height: 38px;
  }

  .primary {
    box-shadow: 0px 8px 34px 0px rgba($c-primary-solyon, 0.4);
    background-color: $c-primary-solyon;
    color: $c-white;

    &:hover:not(:disabled) {
      color: $c-white !important;
      background-color: darken($c-primary-solyon, 8%) !important;
    }
  }

  .primary-light {
    background-color: rgba($c-primary-solyon, 0.2);
    color: $c-primary-solyon;

    &:hover:not(:disabled) {
      color: $c-primary-solyon !important;
      background-color: rgba($c-primary-solyon, 0.4) !important;
    }
  }

  .primary-outlined {
    border: 1px solid $c-primary-solyon;
    color: $c-primary-solyon;
    background-color: $c-white;

    &:hover:not(:disabled) {
      border: 1px solid darken($c-primary-solyon, 8%) !important;
      color: darken($c-primary-solyon, 8%) !important;
      background-color: $c-white !important;
    }
  }

  .gray-light {
    background-color: $c-background-solyon;
    color: $c-gray-1-solyon;

    &:hover:not(:disabled) {
      color: $c-gray-1-solyon !important;
      background-color: darken($c-background-solyon, 8%) !important;
    }
  }

  .default {
    color: $c-text;
    background-color: $c-white;

    &:hover:not(:disabled) {
      color: $c-primary-solyon !important;
      border: none !important;
    }
  }

  .float-right {
    position: absolute;
    right: 16px;
  }

  .float-left {
    position: absolute;
    left: 16px;
  }
}

.solyon-footer {
  * {
    font-family: "Aptos";
  }

  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  color: $c-gray-1-solyon;
  padding-top: 100px;

  &__content {
    font-weight: 400;
    line-height: 1.2;

    strong {
      line-height: 2;
      display: block;
    }

    span {
      font-style: italic;
      display: block;
      padding-bottom: 2px;
    }

    p {
      padding: 2px 0;
    }

    &--expanded {
      display: block;
    }

    &--collapsed {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: relative;
      max-height: 110px;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
      }
    }
  }

  &__more {
    text-align: center;
    font-weight: 700;
    padding-top: 24px;
  }

  &__button {
    color: $c-gray-1-solyon;
    text-decoration: underline;
    font-size: clamp(12px, 2.5vw, 14px);
    font-weight: 400;
    text-align: center;
  }

  &__drawer {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 94% !important;
    margin: auto;

    .ant-drawer-header {
      border: none;
      padding: 24px 24px 16px;

      .ant-drawer-title {
        color: rgba($c-primary-solyon, 1);
        flex-direction: row-reverse;
      }
    }

    .ant-drawer-body {
      padding: 0 24px 16px;
    }
  }

  // @media (width <= 768px) {
  //   z-index: 30;
  //   background-color: $c-white;
  //   padding-top: 40px;
  //   position: absolute;
  //   bottom: 120px;
  //   max-height: 60%;
  //   &__content {
  //     &--collapsed {
  //       display: none;
  //     }
  //     &--expand {
  //     }
  //   }
  // }
}

.solyon-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    height: 160px;
    width: 160px;
    padding: 16px;
  }
}

.solyon-date-picker {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  // width: 100%;

  @media (width <=768px) {
    width: 100%;
  }

  * {
    font-family: "Nunito";
  }

  &.row {
    flex-direction: row;
    align-items: center;

    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-picker {
    height: 40px;
    width: 100%;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .ant-picker-clear {
    span {
      height: 20px;
      width: 20px;
    }
  }

  .ant-picker-focused {
    box-shadow: none;
    border: 1px solid $c-primary-solyon;

    .ant-picker-suffix {
      color: $c-primary-solyon;
    }
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }

    .ant-picker {
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;
    }

    input {
      background: $c-white;

      &::placeholder {
        color: $c-danger-solyon;
      }
    }

    .ant-picker-suffix {
      color: $c-danger-solyon !important;
    }
  }
}

.solyon-input {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;

  * {
    font-family: "Nunito";
  }

  &.row {
    flex-direction: row;
    align-items: center;

    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &--field {
    width: 100%;
  }

  & input {
    height: 40px;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    color: $c-text;
    font-weight: 400;
    font-size: clamp(12px, 3vw, 14px);

    &:hover,
    &:focus {
      box-shadow: none;
      border: 1px solid $c-primary-solyon;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    height: 40px;

    input {
      height: auto;
      border: none;
    }

    &:hover,
    &:focus {
      border: 1px solid $c-primary-solyon;
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: $c-primary-solyon;

    :hover {
      box-shadow: 0;
    }
  }

  & textarea {
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $c-background;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & input:-webkit-autofill {
    background-color: $c-white !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  & input:-moz-autofill {
    background-color: $c-white !important;
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }

    input {
      background: $c-white;
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;

      &:hover,
      &:focus {
        border: 1px solid $c-danger-solyon;
      }
    }
  }
}

.solyon-select {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  width: 100%;

  * {
    font-family: "Nunito";
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
    align-items: center;

    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  .solyon-label {
    &__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    background-color: $c-white;
    height: 40px;
  }

  .ant-select-focused:where(
      .css-dev-only-do-not-override-byeoj0
    ).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 1px solid $c-primary-solyon !important;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) {
    border: none;
  }

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: $c-primary-solyon;
  }

  & .ant-select-selector {
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    & .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & .ant-select-selection-overflow-item {
    & .ant-select-selection-item {
      background: $c-white !important;
      border: 1px solid $c-primary-solyon !important;

      &:hover {
        border: 1px solid $c-primary-solyon !important;
      }
    }
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      ):hover
      .ant-select-selector {
      border-color: $c-danger-solyon;
    }

    .ant-select {
      border: 1px solid $c-danger-solyon;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border: 1px solid $c-danger-solyon;
    }

    .ant-select-selector {
      background: $c-white;
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;

      &:hover,
      &:focus {
        border: 1px solid $c-danger-solyon;
      }
    }
  }
}

.solyon-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $c-primary-solyon;
  font-size: 16px;
  font-weight: 600;

  * {
    font-family: "Nunito";
  }

  &__optional {
    font-style: italic;
    color: $c-gray-light4;
  }

  &__sub {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  &__icon {
    display: flex;
  }

  &__required {
    color: $c-danger-solyon;
  }
}

.hide--icon:empty,
.hide--label:empty,
.hide--label {
  display: none !important;
}

.solyon-card {
  width: 100%;

  &__container {
    border-radius: 8px;
    padding: 16px;
    background-color: $c-background-solyon;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    color: $c-secondary-solyon;
    font-family: "Nunito";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.solyon-checkbox {
  display: flex;
  gap: 8px;

  * {
    font-family: "Nunito";
  }

  &__label {
    line-height: 24px;

    &--text {
      color: $c-text-sec-solyon;
      text-align: justify;
      font-size: clamp(14px, 1.5vw, 16px);
      font-weight: 600;
      line-height: 1.4;
    }

    &--subLabel {
      padding-top: 12px;
      color: $c-text-sec-solyon;
      text-align: justify;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &:has(input[type="checkbox"]:checked) {
    .solyon-checkbox__label--text {
      color: $c-secondary-solyon;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    min-width: 24px;
    min-height: 24px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    border: 1px solid $c-text-sec-solyon;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    position: relative;
  }

  input[type="checkbox"]::before {
    content: "";
    color: $c-primary-solyon;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 50%;
    height: 50%;
    transition: opacity 0.3s;
    background-image: url("../../assets/icons/check_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  input[type="checkbox"]:checked {
    &:before {
      opacity: 1;
    }

    border: 1px solid $c-primary-solyon;
    background-color: $c-primary-solyon;
  }
}

.solyon-scroll-container {
  padding: 12px;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.solyon__needs {
  display: flex;
  justify-content: space-around;
  width: 100%;

  * {
    font-family: "Nunito";
  }

  .selection-grid {
    display: flex;
    gap: 40px;
  }

  .selection-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    width: min-content;
  }

  .selection-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .selection-icon {
    height: 90px;
    object-fit: contain;
  }

  .selection-title {
    color: $c-secondary-solyon;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .selection-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .selection-radio {
    display: flex;
    align-items: center;
    background: $c-background-solyon;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $c-gray-1-solyon;
    white-space: nowrap;
    min-width: 170px;
    cursor: pointer;

    &:hover {
      background: rgba($c-primary-solyon, 0.05);
    }

    &.selected {
      background-color: rgba($c-primary-solyon, 0.1);
      color: $c-primary-solyon;
      font-weight: 700;
    }
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $c-primary-solyon;
    background-color: $c-primary-solyon;
  }

  .ant-radio-wrapper .ant-radio-checked::after {
    border-color: $c-primary-solyon;
  }

  .ant-radio-wrapper {
    margin: 0;

    &:hover .ant-radio-inner {
      border-color: $c-primary-solyon;
    }
  }

  .ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 16px;
    padding-inline-end: 4px;
  }

  &--slides {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    @media (width <=767px) {
      gap: 24px;
      padding: 0 8px;
    }

    .--item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .--head {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          height: 24px;
          width: 24px;
        }

        .--content {
          display: flex;
          flex-direction: column;

          span {
            font-size: clamp(14px, 3vw, 16px);
            font-weight: 600;
          }

          small {
            font-size: clamp(12px, 3vw, 12px);
            font-weight: 400;
            color: $c-gray-1-solyon;
          }
        }
      }
    }
  }

  .ant-slider {
    display: flex;
    align-items: center;

    .ant-slider-rail,
    .ant-slider-step,
    .ant-slider-track {
      height: 8px !important;
      border-radius: 8px;
    }

    .ant-slider-track {
      background-color: rgba($c-primary-solyon, 1);
    }

    .ant-slider-handle {
      width: 24px;
      height: 0;
      inset-block-start: -6px !important;

      &::after {
        content: "";
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 24px;
        height: 24px;
        background-color: #f2f8e9;
        box-shadow: 0 0 0 2px rgba($c-primary-solyon, 1);
        border-radius: 50%;
        cursor: pointer;
        transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
          height 0.2s, box-shadow 0.2s;
      }

      &::before {
        content: "";
        position: absolute;
        inset-inline-start: -2px;
        inset-block-start: -2px;
        width: 24px;
        height: 24px;
        background-color: transparent;
      }
    }

    .ant-slider-mark-text {
      display: none;
    }

    .ant-slider-dot {
      // width: 10px;
      // height: 10px;
      // inset-block-start: -3px;
      width: 1px;
      height: 8px;
      inset-block-start: -2px;
      border-radius: 0;
      border: none;
      background-color: #dbdbdb;

      &:last-child {
        display: none;
      }
    }

    .ant-slider-dot-active {
      display: none;
    }
  }
}

.solyon {
  &-product-container {
    width: 100%;
  }

  &-products {
    width: 100%;

    @media (width <=767px) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  .product__item {
    height: 100%;
    display: flex;
    align-items: center;

    & > div {
      flex-grow: 1;
    }

    &--action {
      width: 100%;
    }

    &--wrapper {
      background-color: $c-white;
      border: 1px solid $c-border-solyon;
      height: 100%;
      border-radius: 16px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      // text-align: center;
      row-gap: 16px;
      min-height: 300px;

      @media (width <=767px) {
        justify-content: center;
      }
    }

    &--header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &--top {
        display: flex;
        justify-content: space-between;
        gap: 4px;
        width: 100%;
      }
    }

    &--title {
      width: 100%;
      font-weight: 600;
      font-size: clamp(14px, 3vw, 18px);
      color: $c-secondary-solyon;
    }

    &--icon {
      height: 24px;
      object-fit: contain;
    }

    &--description {
      color: $c-default-text-gray-light;
      font-size: 12px;
      font-weight: 500;
    }

    &--actions {
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      display: flex;
      font-size: 12px;
      gap: 4px;

      .row {
        cursor: pointer;
        display: flex;
        gap: 4px;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &--title {
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 700;
        color: $c-secondary-solyon;
      }

      &--list {
        display: flex;
        flex-direction: column;
        list-style: none;

        .item {
          display: flex;
          gap: 4px;
          font-size: clamp(10px, 3vw, 12px);
          color: $c-secondary-solyon;
          font-weight: 400;

          svg {
            color: $c-success;
            padding: 4px;
            height: 20px;
            width: 20px;
          }

          span {
            line-height: 20px;
          }
        }
      }
    }

    &--link {
      color: $c-primary-solyon;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      padding-top: 32px;
    }
  }

  .product__item--recommended {
    order: 2;

    @media (width <=767px) {
      order: 1;
    }

    &--header {
      padding: 4px 8px;
      color: $c-primary-solyon;
      // text-align: center;
      font-size: 12px;
      line-height: 12px;
      font-weight: 700;
      border: 1px solid $c-primary-solyon;
      border-radius: 4px;
    }

    .product__item {
      &--wrapper {
        border-color: $c-primary-solyon;
      }

      &--link {
        padding-top: 64px;
      }
    }
  }
}

.related_persons {
  max-width: 700px;
  margin: auto;

  @media (width <=767px) {
    max-width: 100%;
    width: 100%;
  }
}

.beneficiary_container {
  display: flex;
  border: 1px solid $c-primary-solyon;
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  column-gap: 8px;
  margin: auto;

  @media (width <=767px) {
    padding: 8px 16px;
    border-radius: 50px;
  }

  &:has(.hidden) {
    border-radius: 100px;
    padding: 8px 8px 8px 24px;

    @media (width <=767px) {
      padding: 16px 24px;
    }
  }

  @media (width <=767px) {
    // border: none;
    // background-color: rgba($c-primary-solyon, 0.1);
  }

  &--main,
  &--expand--row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (width <=767px) {
      flex-direction: column;
    }

    // .solyon-checkbox {
    //   height: 40px;
    // }

    &--left {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        color: $c-primary-solyon;
      }
    }
  }

  &--main {
    gap: 16px;
    cursor: pointer;

    @media (width <=767px) {
      // justify-content: center;
      // text-align: center;
    }

    img {
      height: 24px;
      object-fit: contain;
    }

    .question {
      line-height: 24px;
      color: $c-primary-solyon;
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 600;
    }
  }

  &--expand {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .divider {
      margin-block: 0;
    }

    &--row {
      gap: 8px;

      @media (width <=767px) {
        align-items: flex-start;
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }

      .datepicker__wrapper {
        flex: 0.8;
        min-width: 100px;
      }

      .children-count {
        background-color: $c-background-solyon;
        border: 1px solid $c-border-solyon;
        border-radius: 100px;
        padding: 8px;
        display: flex;
        gap: 8px;
        width: auto;

        .ant-input,
        .ant-input-disabled {
          text-align: center;
          width: 70px;
          background-color: transparent !important;
          border: none;
        }

        &__button {
          button {
            padding: 16px !important;
          }
        }
      }
    }

    .children-birthday {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--row {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: flex-end;

        @media (width <=767px) {
          align-items: flex-start;
          flex-direction: column;

          & > * {
            width: 100%;
          }
        }

        > .solyon-label {
          height: 40px;
          width: 40%;
        }
      }
    }
  }

  .--action {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (width <=767px) {
      display: none;
    }
  }

  .beneficiary_toggle {
    display: none;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.beneficiary_chips {
  justify-self: center;
  border-radius: 24px;
  padding: 16px;
  gap: 6px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &--button {
    border-radius: 100px;
    padding: 4px 8px;
    font-weight: 500;
    background: rgba($c-primary-solyon, 0.2);
    border: 1px solid $c-primary-solyon;
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: capitalize;
    color: $c-primary-solyon;
    font-size: 14px;

    > button {
      cursor: pointer;
      border: none;
      display: flex;
      color: $c-primary-solyon;

      &:not(:disabled):hover {
        color: $c-primary-solyon;
      }
    }
  }
}

.selected--beneficiaries {
  padding: 4px 10px;
  border-radius: 24px;
  background-color: rgba($c-primary-solyon, 0.1);
  color: rgba($c-primary-solyon, 1);
  font-weight: 600;
}

.solyon__drawer {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 94% !important;
  margin: auto;

  .ant-drawer-header {
    border: none;
    padding: 24px 24px 16px;

    .ant-drawer-header-title {
      flex-direction: row-reverse;
      gap: 8px;
    }

    .ant-drawer-title {
      color: rgba($c-primary-solyon, 1);
      flex-direction: row-reverse;
    }
  }

  .ant-drawer-body {
    padding: 0 24px 16px;
  }

  .beneficiary_container {
    @media (width <=767px) {
      padding: 0;
      border-radius: 0;
      border: none !important;
    }

    &--expand {
      &--row {
        &.childs {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}

.solyon-swiper {
  width: 100%;

  .swiper-slide {
    width: 100%;
  }

  .swiper-wrapper {
    align-items: flex-end !important;
    width: 100%;
    height: auto;
    position: relative;
  }

  .swipper--pagination {
    .slider__controls {
      position: relative;
      z-index: 12;

      & .slider__pagination {
        text-align: center;
        // margin-top: 16px;
        left: 0;
        right: 0;
        margin: 16px auto 0 auto;
        transform: translateX(0%);

        & .swiper-pagination-bullet {
          width: 13px;
          height: 10px;
          display: inline-block;
          background: $c-main;
          opacity: 0.2;
          margin: 0 5px;
          -webkit-border-radius: 20px;
          border-radius: 20px;
          -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          -webkit-transition-delay: 0.5s, 0.5s, 0s;
          -o-transition-delay: 0.5s, 0.5s, 0s;
          transition-delay: 0.5s, 0.5s, 0s;
        }
      }

      & .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
      }

      & .slider__pagination .swiper-pagination-bullet-active {
        opacity: 1;
        background: $c-main;
        width: 30px;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }
}

.solyon-swiper + .swiper__arrows {
  margin-top: 32px;
  position: relative;
  width: max-content;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 50px;
  padding: 0 2px;
  margin: auto;
  margin-top: 32px;

  .swiper-button-prev,
  .swiper-button-next {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: transparent;
    background-color: rgba($c-primary-solyon, 1);
    border: none;
    color: $c-white;
    cursor: pointer;
    z-index: 1;
    position: static;
    margin: auto 0;

    &::after {
      color: $c-white;
      font-size: 18px;
      font-weight: 600;

      @media (width <=991px) {
        font-size: 16px;
      }
    }

    @media (width <=991px) {
      height: 40px;
      width: 40px;
    }
  }

  .swiper-button-disabled {
    background-color: $c-gray-2-solyon;
  }
}

.solyon-cost {
  &--main {
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
    color: $c-secondary-solyon;

    &--integer {
      font-size: 62px;
      font-weight: 700;
      line-height: 1;
    }

    &--decimal {
      font-size: 24px;
      display: flex;
      gap: 4px;
      line-height: 31px;
      white-space: nowrap;
    }

    &--period {
      color: $c-primary-solyon;
      font-size: 21px;
      line-height: 1;
    }
  }

  &--secondary {
    color: $c-gray-1-solyon;
    font-size: 12px;
    line-height: normal;
  }

  @media (width <=768px) {
    &--main {
      &--integer {
        font-size: clamp(40px, 3vw, 54px);
      }

      &--decimal {
        font-size: clamp(14px, 3vw, 20px);
        line-height: 24px;
      }

      &--period {
        font-size: clamp(12px, 3vw, 18px);
      }
    }
  }
}

.solyon-upsells {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
  gap: 24px;
  width: 100%;

  @media (width <=768px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 16px;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--link {
      cursor: pointer;
      color: $c-text-sec-solyon;
      font-family: "Nunito";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      text-decoration-thickness: 4%;
      text-underline-offset: 12%;
      text-underline-position: from-font;
      text-align: center;

      // @media (width <=768px) {
      //   display: none;
      // }
    }

    .--link-mobile {
      display: none;

      @media (width <=768px) {
        display: flex;
      }
    }

    &--link--hidden {
      visibility: hidden;
      display: none;
    }
  }

  &-card {
    padding: 40px 24px;
    width: 100%;
    border-radius: 24px;
    border: 1px solid $c-border-solyon;

    @media (width <=1024px) {
      padding: 32px 16px;
    }

    @media (width <=768px) {
      padding: 16px 24px;
      border-radius: 16px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      // flex: 1 0 0;

      @media (width <=768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      img {
        height: 18px;
        object-fit: contain;
        max-width: 100%;
      }

      button {
        height: 26px;
        padding: 6px;
      }

      .--logo {
        width: 35%;
        height: 40px;
        object-fit: contain;
      }

      .--cost {
        width: 35%;
        display: flex;
        justify-content: flex-end;
      }
    }

    &__not-selected {
      opacity: 0.5;
      filter: grayscale(100%);
    }

    &__max {
      height: auto;
      line-height: 26px;
    }
  }

  &__max {
    display: none;

    color: #979797;
    font-size: clamp(12px, 3vw, 16px);

    @media (width <=768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.solyon-upsells-recap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__card {
    border-radius: 100px;
    background-color: $c-background-solyon;
  }

  &__container {
    display: flex;
    padding: 8px 16px 8px 34px;
    align-items: center;
    gap: 24px;
  }

  &__text {
    max-width: 124px;
    color: $c-secondary-solyon;
    font-family: "Nunito";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  &--gray {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }

  @media (width <=768px) {
    &__container {
      padding: 0;
    }

    &__card {
      background-color: transparent;
    }

    &__text {
      display: none;
    }
  }
}

.solyon-upsells__details {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &--header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &--title {
    color: $c-primary-solyon;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &--icon {
    max-height: 20px;
    object-fit: contain;
  }

  .guarantees-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;

    th {
      color: $c-primary-solyon;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    th,
    td {
      padding: 8px;
      text-align: left;
    }

    td {
      color: $c-secondary-solyon;
    }
  }

  tr:not(:has(th)):nth-child(2n + 1) {
    background-color: $c-background-solyon;
  }
}

.solyon-info {
  padding: 16px 24px;
  border-radius: 24px;
  background: rgba($c-primary-solyon, 0.05);
  max-width: 724px;
  color: $c-primary-solyon;
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.solyon-recap {
  width: 100%;
  display: flex;

  * {
    font-family: "Nunito";
  }

  &__col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &:not(:last-child) {
      padding: 0 24px;
      border-right: 1px solid $c-border-solyon;
    }

    &:last-child {
      padding-left: 24px;
    }
  }

  .v-center {
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: "Nunito";
    text-align: center;
    color: $c-gray-1-solyon;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__edit {
    display: flex;
    align-items: center;
    gap: 12px;

    & > svg {
      width: 24px;
      object-fit: contain;
      color: $c-primary-solyon;
    }

    &--icon {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $c-background-solyon;
      min-width: max-content;
      border-radius: 100px;
      padding: 4px;
      border-radius: 100px;

      svg {
        color: $c-gray-1-solyon;
      }
    }

    &--text {
      flex: 1;

      * {
        font-family: "Nunito";
      }

      span {
        color: $c-gray-1-solyon;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &--value {
        color: $c-secondary-solyon;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &__prices {
    * {
      font-family: "Nunito";
      text-align: left;
    }

    &--row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 4px;

      div,
      span {
        text-align: left;
        color: $c-gray-1-solyon;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: $c-primary-solyon;
      }
    }
  }

  &__partnership {
    padding: 8px 24px;
    border: 1px solid $c-primary-solyon;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 100px;

    &:has(input) {
      border-radius: 24px;
    }

    &--row {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg {
        height: 24px;
        object-fit: contain;
        color: $c-primary-solyon;
      }

      span {
        color: $c-primary-solyon;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &--grow {
        flex: 1;
      }

      &--value {
        color: $c-primary-solyon;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background-color: $c-primary-solyon-background;
        padding: 2px 10px;
        border-radius: 100px;
      }
    }
  }

  &__info {
    color: $c-gray-1-solyon;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    padding-top: 8px;
  }

  @media (width <=768px) {
    flex-direction: column;

    &__title {
      text-align: left;
    }

    &__col {
      &:not(:last-child) {
        padding: 16px 0;
        border-right: none;
        border-bottom: 1px solid $c-border-solyon;
      }

      &:last-child {
        padding-top: 16px;
      }

      .solyon-card__container {
        padding: 0;
        background-color: $c-white;
      }
    }
  }
}

.solyon-product-card {
  * {
    font-family: "Nunito";
  }

  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  background: $c-primary-background-solyon;
  background-image: url("../../assets/images/motif.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;

  &__icon {
    height: 24px;
    object-fit: contain;
  }
}

.solyon-documents {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
  }
}

.solyon-document,
.solyon-document-uploader {
  * {
    font-family: "Nunito";
  }

  min-height: 55px;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  border: 1px solid $c-border-solyon;
  background: $c-white;

  &__icon {
    color: $c-pink-solyon;
  }

  &__content {
    flex: 1;

    &--name {
      color: $c-secondary-solyon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &--size {
      color: $c-gray-1-solyon;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  &__action {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $c-background-solyon;
    min-width: max-content;
    border-radius: 100px;
  }

  &__actions {
    display: flex;
    gap: 4px;
  }
}

.solyon-document {
  &__action {
    padding: 4px;
    border-radius: 100px;

    svg {
      color: $c-gray-1-solyon;
    }
  }

  &__action--danger {
    background-color: rgba($c-danger-solyon, 0.05) !important;

    svg {
      color: $c-danger-solyon !important;
    }
  }
}

.solyon-document-uploader {
  &__icon {
    color: $c-gray-2-solyon;
  }

  &__content {
    &--name {
      color: $c-gray-1-solyon;
    }

    &--icon {
      color: $c-gray-1-solyon;
    }
  }

  &__action {
    padding: 4px 12px;

    svg,
    span {
      color: $c-primary-solyon;
    }
  }

  @media (width <=768px) {
    &__action {
      padding: 8px;

      span {
        display: none;
      }
    }
  }
}

.solyon-navbar {
  * {
    font-family: "Nunito";
  }

  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $c-white;
  width: 100%;

  &__row,
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  &__row {
    padding: 24px 40px;

    img {
      max-height: 30px;
      object-fit: contain;
    }

    @media (width <=768px) {
      padding: 12px 24px;

      img {
        max-height: 24px;
      }
    }
  }

  .ant-progress-line {
    display: block;
    font-size: 0;
  }

  .ant-progress {
    margin: 0;

    .ant-progress-outer {
      height: 0 !important;
    }

    .ant-progress-inner {
      background-color: $c-primary-background-solyon;
      height: 2px;
      border-radius: 0;
      color: $c-primary-solyon;

      .ant-progress-bg {
        background-color: $c-primary-solyon;
        border-radius: 0;
        height: 2px !important;
      }
    }
  }
}

.solyon-help {
  * {
    font-family: "Nunito";
  }

  cursor: pointer;
  color: $c-gray-1-solyon;
  text-align: center;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid $c-border-solyon;

  svg {
    color: $c-primary-solyon;
  }

  &__modal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &--actions {
      display: flex;
      flex-wrap: wrap-reverse;
      column-gap: 16px;
      row-gap: 8px;
    }
  }

  @media (width <=768px) {
    border: none;
    padding: 0;

    span {
      display: none;
    }

    &__modal {
      &--actions {
        .solyon-button {
          width: 100%;
        }
      }
    }
  }
}

.solyon-cart {
  * {
    font-family: "Nunito";
  }

  min-width: 80px;
  cursor: pointer;
  color: $c-white;
  background-color: $c-primary-solyon;
  text-align: center;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;

  &__modal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

.solyon-contact {
  * {
    font-family: "Nunito";
  }

  cursor: pointer;
  color: $c-gray-1-solyon;
  text-align: center;
  font-family: "Nunito";
  font-size: clamp(12px, 3vw, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  @media (width <=768px) {
    padding: 8px 8px;
  }

  svg {
    color: $c-gray-1-solyon;
  }

  &__modal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

// helpers
:where(.css-dev-only-do-not-override-byeoj0).ant-drawer-bottom
  > .ant-drawer-content-wrapper {
  box-shadow: none !important;
}


.rgpd__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
  margin: 0 auto;

  @media (width <= 991px) {
    width: 100%;
  }
}